import { InteractionConfig } from './types';
import { motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	if (props.shouldPlay) {
		// play videos, sounds, etc
	}

	return (
		<div className="relative font-londrina w-screen h-screen border-2 grid place-items-center font-londrina">
			<video src="/colorburst.webm" autoPlay className="absolute w-full h-full" />
			<audio src="/burst.mp3" autoPlay />
			<motion.div
				initial={{ opacity: 0, y: -10 }}
				animate={{
					opacity: 1,
					y: [
						-9, // Start position
						-10, // Start position
						0, // Goes down
						-4, // Back up
					],
				}}
				transition={{
					delay: 0,
					ease: 'easeInOut',
					opacity: {
						duration: 0.3,
					},
					y: {
						duration: 0.5,
						yoyo: 0, // Loop the animation forever
					},
				}}
				className="min-w-[300px] max-w-[500px] flex items-center flex-col justify-center gap-4 text-center text-white relative mb-5 mr-7"
			>
				<div className="flex items-center gap-2 bg-black bg-opacity-40 rounded-lg p-2 ">
					<img alt="b" width={40} src="/tangia.png" />
					<p className="text-white text-3xl font-bold grow self-center">Unlocked:</p>
				</div>
				<img alt="b" className="max-w-[140px] rounded-lg" src={props.config.picSrc} />
				<p className="text-white bg-black bg-opacity-40 rounded-lg p-2 text-3xl font-bold grow self-center">{props.config.name}</p>
			</motion.div>
		</div>
	);
}
