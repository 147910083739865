export interface InteractionConfig {
	picSrc: string;
	name: string;
	timeout?: number; // time after which the interaction gets hidden - in ms
}

export function validateConfig(c: InteractionConfig): boolean {
	if (!c) {
		return false;
	}
	if (!c.picSrc) {
		return false;
	}
	if (!c.timeout) {
		c.timeout = Number(new URLSearchParams(window.location.search).get('timeout') || '7000');
	}
	return true;
}
